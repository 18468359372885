/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/material.theme.css";
@import "~animate.css/animate.min";
:root {
  --ct-primary: #3456ff;
  --ct-primary-scroll: #ebefff;
  --ct-primary-disabled: #8297fd;
  --ct-primary-light: #d6ddff;
  --ct-gray-light: #f8f8fa;
  --ct-gray: #6a6b7e;
  --ct-gray-line: #9c9da9;
  --ct-white: #ffffff;
  --ct-dark: #070928;
  --ct-danger: #e32c2c;
  --ct-danger-disabled: #f08c8c;
  --ct-green: #009200;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Nunito", sans-serif;
  background-color: var(--ct-gray-light);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  background: var(--ct-primary-scroll);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: var(--ct-primary);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ct-primary);
}

main {
  padding: 20px;
  height: 79%;
}

.block {
  width: 19px;
  display: -webkit-inline-box;
}

.content-btns .mat-button,
.content-btns .mat-raised-button,
.content-btns .mat-stroked-button {
  margin: 0 8px;
}

button {
  border-radius: 10px !important;
}

button.blue,
button.red,
button.swal2-confirm.swal2-styled {
  width: 190px;
  height: 48px;
  font-weight: 700;
  font-size: 16px;
  font-family: "Nunito";
  box-shadow: 0px 6px 10px rgba(52, 86, 255, 0.1) !important;
}

.red-btn-swal {
  background-color: var(--ct-danger) !important;
}

.red-link-swal {
  color: var(--ct-danger) !important;
}

.red {
  float: left;
  margin-top: 15px;
  color: var(--ct-danger);
  border: 2px solid var(--ct-danger) !important;
}

.red-link {
  float: left;
  margin-top: 15px;
  cursor: pointer;
  mat-icon {
    color: var(--ct-danger);
    // border: 2px solid var(--ct-danger);
  }
  &.empty {
    cursor: unset;
  }
}

button.simple-primary {
  background: var(--ct-white);
  border: 2px solid var(--ct-primary);
  box-sizing: border-box;
  box-shadow: 0px 6px 10px rgb(22 49 114 / 10%) !important;
  border-radius: 5px !important;
  color: var(--ct-primary);
  font-family: "Nunito";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  &.mat-button-disabled {
    color: var(--ct-primary-disabled) !important;
    border-color: var(--ct-primary-disabled) !important;
    background-color: var(--ct-gray-light) !important;
  }
  &:nth-child(1n) {
    margin-right: 16px;
  }
}

button.simple-danger {
  background: var(--ct-white);
  border: 2px solid var(--ct-danger);
  box-sizing: border-box;
  box-shadow: 0px 6px 10px rgb(22 49 114 / 10%) !important;
  border-radius: 5px !important;
  color: var(--ct-danger);
  font-family: "Nunito";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  &.mat-button-disabled {
    color: var(--ct-danger-disabled) !important;
    border-color: var(--ct-danger-disabled) !important;
    background-color: var(--ct-gray-light) !important;
  }
  &:nth-child(1n) {
    margin-right: 16px;
  }
}

button.swal2-cancel.swal2-styled {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--ct-primary);
  background: unset;
  &:hover {
    background: unset !important;
  }
  &:focus {
    box-shadow: unset !important;
  }
}

button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: unset;
}

.green-snackbar {
  background: var(--ct-green);
  color: var(--ct-white);
}

.green-snackbar button {
  background-color: var(--ct-green);
  color: var(--ct-white);
  border: none;
}

.danger-snackbar {
  background: var(--ct-danger);
  color: var(--ct-white);
}

.danger-snackbar button {
  background-color: var(--ct-danger);
  color: var(--ct-white);
  border: none;
}

.ng-select .ng-select-container {
  min-height: 41px !important;
}

.ng-select .ng-select-container .ng-value-container {
  border-top: 0.6em !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  margin-top: 9px;
}

.ng-value.ng-star-inserted {
  margin-top: 8px;
}

ng-dropdown-panel {
  margin-top: -15px;
  border-radius: 5px;
  width: fit-content !important;
}

.form-items {
  .ng-select {
    width: 200px !important;
  }
  .red-link {
    margin-top: 5px;
  }
}

.swal2-popup.swal2-modal {
  background: var(--ct-white);
  border-radius: 20px;
  padding: 24px;
}

.swal2-actions {
  flex-direction: column;
}

h2#swal2-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: var(--ct-primary);
}

div#swal2-html-container {
  font-family: "Nunito";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--ct-dark);
}

.mat-drawer-container {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
}

.mat-toolbar.mat-primary,
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-calendar-body-selected,
button.swal2-confirm.swal2-styled {
  background: var(--ct-primary);
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-elevation-z8 {
  box-shadow: unset;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 7px 0px 7px 0px !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--ct-primary) !important;
}

.mat-drawer-content,
.mat-paginator-container {
  background-color: var(--ct-gray-light);
}

.mat-drawer-inner-container {
  min-width: 335px !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(7, 9, 40, 0.2) !important;
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-today {
  color: var(--ct-dark) !important;
  background: var(--ct-primary-light) !important;
}

.content-data {
  padding: 15px 0px 15px 0px;
}

.row-data {
  display: inline-flex;
  padding: 12px 0px 0px 0px;
  &.top {
    border-top: 1px solid #bfbfbf;
    margin-top: 12px;
  }
  div:nth-child(1) {
    width: 110px;
    text-align: left;
  }
  div:nth-child(2) {
    width: 150px;
    text-align: right;
    font-weight: bold;
  }
}

.hide-left-bar {
  background: var(--ct-primary);
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  align-self: center;
  width: 20px;
  justify-content: center;
  cursor: pointer;
  color: #FFF;
  font-weight: bold;
  transform: translate(-50%, -50%);

  span {
    display: flex;
  }
}

div#mat-select-12-panel,
div#mat-select-20-panel,
div#mat-select-4-panel {
  max-width: max-content !important;
}

mat-checkbox {
  .mat-checkbox-background {
    background: var(--ct-white);
    box-sizing: border-box;
    border: 1.5px solid var(--ct-primary);
    box-shadow: 0px 6px 10px rgb(51 51 51 / 10%);
    border-radius: 3px;
  }
  .mat-checkbox-frame {
    border-radius: 3px;
  }
  .mat-ripple-element {
    background: var(--ct-primary) !important;
  }
}

app-salaries,
app-taxes,
app-bulk-load {
  .content-loading {
    position: fixed !important;
  }
}

app-root,
mat-sidenav-container {
  height: 100%;
}

app-receipt,
app-receipt-sale {
  display: flex;
  height: 100%;
}

app-taxes,
app-salaries,
app-taxes-sheetload,
app-salaries-sheetload {
  animation: fadeIn;
  animation-duration: 0.5s;
  table {
    animation: fadeIn;
    animation-duration: 0.5s;
  }
}

app-voucher-history,
app-taxes-sheetload,
app-salaries-sheetload {
  animation: fadeIn;
  animation-duration: 0.5s;
  mat-tab-group {
    animation: fadeIn;
    animation-duration: 0.5s;
  }
}

app-voucher-history,
app-taxes-sheetload,
app-salaries-sheetload {
  animation: fadeIn;
  animation-duration: 0.5s;
  mat-tab-group.mat-tab-group.mat-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    mat-tab-header.mat-tab-header {
      width: fit-content;
      border-bottom: unset;
      box-shadow: 0px 6px 10px rgb(51 51 51 / 10%);
      border-radius: 10px;
      margin-bottom: 35px;
    }
    .mat-tab-body-wrapper {
      width: 100%;
    }
    .mat-tab-label {
      font-size: 16px;
      font-family: "Nunito";
      &[aria-selected="true"] {
        font-weight: 700;
        background: var(--ct-primary);
        color: var(--ct-white);
      }
      &[aria-selected="false"] {
        font-weight: 400;
        background: var(--ct-primary-light);
        color: var(--ct-dark);
      }
    }
    .mat-tab-label:nth-child(1) {
      opacity: 1;
      border-radius: 10px 0px 0px 10px;
    }
    .mat-tab-label:nth-child(2) {
      border-radius: 0px 10px 10px 0px;
      opacity: 1;
    }
    mat-ink-bar.mat-ink-bar {
      visibility: hidden !important;
    }
  }
  .mat-button-toggle-standalone,
  .mat-button-toggle-group {
    border: 0px;
  }
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 25px;
  }
  .mat-button-toggle-group-appearance-standard .mat-button-toggle {
    background: var(--ct-white);
    border: 1px solid rgba(7, 9, 40, 0.6) !important;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: "Nunito";
    height: 27px;
    width: min-content;
    .mat-icon.notranslate.material-icons.mat-icon-no-color {
      width: 18px;
      height: 18px;
      font-size: 18px;
    }
    .mat-button-toggle-button {
      &[aria-pressed="true"] {
        // font-weight: 700;
        background: var(--ct-gray);
        color: var(--ct-white);
      }
    }
  }
  span.mat-ripple.mat-button-toggle-ripple {
    border-radius: 30px;
  }
  .mat-button-toggle-appearance-standard {
    margin-right: 12px;
  }
}

app-buys,
app-sales,
app-date,
app-taxes-sheetload,
app-salaries-sheetload {
  mat-grid-list {
    padding: 10px 0px 10px 0px;
    .mat-grid-tile .mat-figure {
      display: flex;
      justify-content: flex-start;
    }
  }
}
